body {
  font: 14px "Century Gothic", Futura, sans-serif;
  /* margin: 20px; */
  /* background: #D3D3D3; */
}

html,
body {
  height: 100%;
  /* overflow: hidden;   */
}

#main-container {
  display: flex;
  flex-direction: row;
}

#info-pane {
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
}

#main-board {
  width: 60%;
  height: 100%;
}

.board-row {
  /* height: 30%; */
  display: flex;
  flex: 1 1 auto;
  /* min-width: 80vw; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.container-override {
  /* background-color: white;
  padding: 0px; */
  /* height: 100%; */
  /* min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh; */
  /* display: flex;
  flex: 0 0 25em; */
}

#current-game-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#stats-section {
}

#unlockables-section {
}

#root {
  /* min-width: 100%; */
}

.main-row {
  /* margin-right: 0px; */
}

.card-remaining-icon-div {
  display: flex;
  /* flex-wrap: nowrap; */
  max-width: 100%;
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  /* flex-direction: row-reverse; */
  /* justify-content: right; */
  /* align-items: stretch; */
  /* padding-right: 15px; */
}

#guess-history-div {
  font-size: 0.7em;
}

.cards-remaining-card {
  margin-right: -30px;
  height: 50px;
}

#previous-guesses-table td {
  /* td, th { */
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  /* } */
}

.guess-icon {
  height: 30px;
  width: 30px;
}

.info-div-buttons {
  font-size: 0.4rem;
  height: 20px;
  /* float: right; */
}

.info-div-buttons-top {
  font-size: 0.4rem;
  height: 20px;
  /* float: right; */
  margin-bottom: 5px;
}

#previous-guess-info {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

#players-guess {
  margin-top: 5px;
  font-size: 0.6rem;
  display: flex;
  justify-content: left;
  align-items: center;
}

.card-images-guess {
  /* border-radius: 6%; */
  /* height: 75px; */
  max-height: 50px;
  /* border: .5px solid #dee2e6; */
}

.stats-header {
  font-weight: bold;
  font-size: calc(0.5em + 1vmin);
}

.custom-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.game-summary {
  background-color: white;
  margin-bottom: 5px;
}

.table-override {
  margin-bottom: 0px;
}

.table-override-cards {
  margin-bottom: 5px;
  margin-top: 5px;
  width: 40%;
  border: 1px solid #dee2e6;
}

/* .guess-td{
  display: flex;
  align-items: center;
  justify-content: center;
}

.guess-tr{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
} */

/* .guess-td.guess-td-top{
  border-top: none;
  padding-top: 5px;
  padding-bottom: 5px;
} */

.col-override {
  /* padding: 0px; */
  /* padding-right: 0px;
  padding-left: 0px; */
  /* overflow: hidden; */
  /* padding-right: 0px;
  padding-left: 0px; */
  /* min-height: 100vh; */
}

ol,
ul {
  padding-left: 30px;
}

/* .board-row:after {
  clear: both;
  content: "";
  display: table;
} */

.dropdown-item {
  font-size: 0.8em;
  color: black;
}

/* .board-row {
  display: flex;
  flex: 1 1 auto;
  min-width: 80vw;
  justify-content: space-between;
  align-items: center;
} */

.refactored-row {
  margin-bottom: 5px;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  /* border: 1px solid #999; */
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  /* height: 324px; */
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  /* width: 223px; */
  position: relative;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

/* .game {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
} */

.square {
  display: flex;
  margin-bottom: 10px;
}

.game-info {
  margin-left: 20px;
  padding: 10px;
}

.you-lose-modal {
  background-color: #343a40;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* opacity: 1; */
}

.you-lose-overlay {
  height: 100%;
  width: 100%;
  color: black;
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 0px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.you-win-modal {
  background-color: #343a40;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: auto;
  /* opacity: 1; */
}

.you-win-overlay {
  height: 100%;
  width: 100%;
  color: black;
  background-clip: content-box;
  background-color: rgba(40, 191, 224, 0.6);
  z-index: 15;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 0px 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .square .top-button { */

.higher-button {
  top: 0;
  width: 70%;
  height: 50%;
  border-radius: 4%;
  position: absolute;
  background-color: "transparent";
  z-index: 10;
}

a {
  color: white;
}

a:hover {
  color: #d3d3d3;
}

.lower-button {
  bottom: 0;
  width: 70%;
  height: 50%;
  border-radius: 4%;
  position: absolute;
  background-color: "transparent";
  z-index: 10;
}

.card-box {
  display: flex;
  align-items: center;
}

.square .middle-button {
  /* right: 20px;
  top: 22px;
  width: 66px; */
  left: 20px;
  top: 60px;
  /* float: left; */
  position: absolute;
  /* left: 50%; */
  /* position: relative; */
  /* top: 50%;
    left: 50%; */
}

.square .bottom-button {
  /* right: 20px; */
  /* top: 39px; */
  bottom: 0px;
  left: 30px;
  /* width: 66px; */
  /* float: left; */
  position: absolute;
  /* left: 50%; */
  /* position: relative; */
  /* top: 50%;
    left: 50%; */
}

.infoDiv {
  /* width: 300px; */
  /* max-width: 100%; */
  /* font-size: 12px; */
  padding: 15px 30px;
  font-size: calc(0.4em + 1vmin);
}

.stat-line {
  padding-bottom: 2px;
}

.cards-class {
  /* width: 200px; */
  /* max-width: 100%; */
  /* min-width: 80px; */
  /* min-height: 130px; */
  min-width: 15vh;
  max-width: 18vw;
  /* height: 290px; */
  /* max-height: 100%; */
  /* margin-left: 5px;
  margin-right: 5px; */
  /* vertical-align: middle; */
}

.box-game-header {
  font-size: 25px;
}

/* .game-board {
  display: flex;
  flex: 1 1 auto;
} */

@media screen and (orientation: portrait) {
  /* .game-board{
    bottom: 0px;
    position: absolute;
  }
  html,body,#root,#main-container{
    height:97%;
  } */

  #root,
  #main-container {
    height: 100%;
    overflow: scroll;
  }
  #main-container {
    flex-direction: column;
  }
  #info-pane {
    width: 100%;
    height: 40%;
  }
  #main-board {
    width: 100%;

    /* position: fixed;
    bottom: 0; */
  }
  /* #main-game-board{
    position: absolute;
    bottom: 0;
  } */
}
